// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-icons/font/bootstrap-icons.scss';
@import '~select2/dist/css/select2.min.css';
@import '~owl.carousel/dist/assets/owl.carousel.min.css';
@import './../css/timepicker.min.css';

:root{
    --theme-primary-color: #0199FE;
    --theme-secondary-color: #00CC9A;
}
body::-webkit-scrollbar, body ::-webkit-scrollbar{
    height: 10px;
    width: 10px;
    border-radius: 20px;
}
body::-webkit-scrollbar-track, body ::-webkit-scrollbar-track{
    background-color: var(--bs-gray-200);
    border-radius: 20px;
}
body::-webkit-scrollbar-thumb, body ::-webkit-scrollbar-thumb{
    background-color: var(--bs-gray-600);
    border-radius: 20px;
}
body::-webkit-scrollbar-thumb:hover, body ::-webkit-scrollbar-thumb:hover{
    background-color: var(--bs-gray-300);
}
/* Toast */
.toast-container{
    position: fixed;
    padding: 0;
    transition: padding 0.3s;
}
.toast-container:has(.toast.show){
    padding: 15px 10px;
}
.toast-container .toast{
    transition: display 0.3s;
}
/* Form Control */
.form-group .form-control{
    font-weight: 600;
    border-radius: 2px;
}
.form-group:has(.show-password){
    position: relative;
}
.form-group .show-password{
    position: absolute;
    right: 10px;
    left: unset;
    bottom: 1px;
    font-size: 20px;
    cursor: pointer;
}
.form-group:has(.form-control[type="text"]) .show-password{
    color: var(--bs-gray);
}
.form-group:has(.form-control[type="password"]) .show-password{
    color: var(--bs-black);
}
.form-control:focus, .btn:focus{
    box-shadow: none !important;
}
/* Pagination */
ul.pagination{
    padding: 0;
    margin: 0;
}
/* Button */
.btn{
    font-weight: 600;
    border-radius: 2px;
}
.btn-primary-gradient{
    background-image: linear-gradient(270deg, var(--theme-secondary-color), var(--theme-primary-color));
    color: var(--bs-white);
    transition: background 0.3s ease-in-out;
}
.btn-primary-gradient:hover{
    background-image: linear-gradient(270deg, var(--theme-primary-color) 0%, var(--theme-primary-color) 100%);
    color: var(--bs-white);
}
/* Select 2 */
.select2{
    height: 38px;
}
.select2 .select2-selection{
    height: 100%;
    border-color: #ced4da;
}
.select2 .select2-selection:not(.select2-selection--multiple) .select2-selection__rendered{
    padding-top: 5px;
}
.select2 .select2-selection .select2-selection__arrow{
    top: 5px;
}
html[dir="rtl"] body .select2-container--default .select2-selection--multiple.select2-selection--clearable{
    padding-left: 25px;
    padding-right: 5px;
}
html[dir="rtl"] body .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__clear{
    right: unset;
    left: 0;
}
/* Breadcrumb */
.breadcrumb{
    background-color: var(--bs-gray-100) !important;
    padding: 10px !important;
    border-radius: 5px !important;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    border: 1px solid var(--bs-gray-200);
}
.breadcrumb > ul{
    list-style: none;
    padding: 0;
    margin: 0;
}
.breadcrumb > ul li{
    display: inline-block;
}
.breadcrumb > ul li:not(:last-child){
    position: relative;
}
.breadcrumb > ul li:not(:last-child)::after{
    position: absolute;
    top: 0;
    right: 0;
    left: unset;
    font-family: "bootstrap-icons";
    content: '\F285';
}
.breadcrumb > ul li:last-child span{
    color: #0d6efd;
    font-weight: bold;
}
.breadcrumb > ul li > a{
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    color: var(--bs-gray-700);
    transition: color 0.2s;
}
.breadcrumb > ul li:not(:last-child) > a{
    margin-right: 20px;
    margin-left: 0;
}
.breadcrumb > ul li > a:hover{
    color: var(--bs-gray-900);
}
/* Banner */
.banner{
    min-height: 350px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}
.banner::before{
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100px;
    background-image: linear-gradient(270deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}
.banner::after{
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100px;
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}
.banner img{
    width: 100%;
    min-height: 350px;
    height: 100%;
    object-fit: cover;
}
.banner .content{
    position: absolute;
    top: 0;
    right: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    bottom: 0;
    margin: auto 0;
    gap: 15px;
}
.banner .content .title{
    color: var(--bs-white);
    font-size: 36px;
    font-weight: bold;
}
.banner .link{
    font-size: 20px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
.banner .content .subtitle{
    color: var(--bs-white);
    font-size: 30px;
    font-weight: bold;
}
/* Program Carousel */
.program-carousel .title{
    font-size: 20px;
    font-weight: bold;
}
.program-carousel .owl-carousel .item{
    height: 250px;
    padding: 15px 10px;
    cursor: pointer;
}
.program-carousel .owl-carousel .item .image{
    height: 165px;
    width: 100%;
    margin-bottom: 15px;
}
.program-carousel .owl-carousel .item .image img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.program-carousel .owl-carousel .item .title{
    font-size: 16px;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
}
.program-carousel .owl-carousel .item .subtitle{
    font-size: 14px;
}
.program-carousel .owl-carousel {
    padding: 0 45px;
}
.program-carousel .owl-carousel .owl-nav .owl-prev, .program-carousel .owl-carousel .owl-nav .owl-next{
    position: absolute;
    font-size: 80px;
    font-weight: 400;
    top: 0;
    height: 250px;
    width: 60px;
    background-color: rgba(200, 200, 200, 0.1);
}
.program-carousel .owl-carousel .owl-nav .owl-prev.disabled, .program-carousel .owl-carousel .owl-nav .owl-next.disabled{
    opacity: 0;
    visibility: hidden;
}
.program-carousel .owl-carousel .owl-nav .owl-prev{
    right: 0;
}
.program-carousel .owl-carousel .owl-nav .owl-next{
    left: 0;
}
footer{
    margin-top: 50px;
    padding-bottom: 30px;
}
footer .socials{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}
footer .socials ul{
    list-style: none;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 5px 15px;
    border: 1px solid var(--bs-gray-400);
    width: auto;
    border-radius: 50px;
}
footer .socials ul li{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
footer .socials ul li:not(:last-child)::after{
    position: absolute;
    content: '';
    height: 20px;
    width: 1px;
    background-color: var(--bs-gray-400);
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto 0;
}
footer .socials ul li a{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    color: var(--bs-gray-700);
    text-decoration: none;
}
footer .socials ul li:hover a{
    color: var(--theme-secondary-color);
}
footer .copyright{
    font-size: 14px;
    color: var(--bs-gray-600);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 20px;
}
@media (max-width: 991.98px) {
    .banner .content{
        top: 0;
        right: 0;
        left: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-direction: column;
        bottom: 30px;
        margin: auto 0;
        gap: 15px;
    }
}